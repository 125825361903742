// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $Szybkapraca-primary: mat-palette($mat-indigo);
// $Szybkapraca-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
// $Szybkapraca-warn: mat-palette($mat-red);

// // Create the theme object. A theme consists of configurations for individual
// // theming systems such as "color" or "typography".
// $Szybkapraca-theme: mat-light-theme(
//   (
//     color: (
//       primary: $Szybkapraca-primary,
//       accent: $Szybkapraca-accent,
//       warn: $Szybkapraca-warn,
//     ),
//   )
// );

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include angular-material-theme($Szybkapraca-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  scrollbar-width: thin;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-input-element {
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
}

input {
  /* Remove First */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  padding: 0;
  border: none;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

::placeholder {
  color: #858585;
  opacity: 1; //firefox
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

/* Firefox 18- */
input:focus::-moz-placeholder {
  color: transparent;
}

/* Firefox 19+ */
input:focus:-ms-input-placeholder {
  color: transparent;
}

/* oldIE ;) */
.mat-toolbar {
  background: white;
  display: flex;
  flex-direction: row;
}

.mat-toolbar-row,
.mat-toolbar-single-row {
  height: 159px;
  padding: 0 0;
}

input:active {
  border: none;
}

.mat-form-field-placeholder:focus {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .mat-tab-label {
    padding: 0 16px !important;
  }

  .mat-tab-group {
    overflow-x: auto;
    width: 100%;
  }

  .mat-tab-list {
    overflow-x: auto;
  }

  .mat-ink-bar {
    display: none;
  }

  .mat-tab-header {
    border-bottom: none;
  }
  body {
    overflow: hidden;
  }
}

@supports (-webkit-touch-callout: none) {
  body {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@media only screen and (min-width: 1001px) {
  .mat-tab-label {
    padding: 0 0px !important;
    min-width: fit-content !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .mat-tab-group {
    overflow-x: auto;
    width: 100%;

    box-sizing: border-box;
  }

  .mat-tab-list {
    overflow-x: auto;
  }

  .mat-ink-bar {
    display: none;
  }

  .mat-tab-header {
    border-bottom: none;
  }
  .mat-tab-label-container {
  }
  .mat-tab-label-content {
    width: 100%;
  }
  body {
    height: 100vh;
  }
}

.mat-tab-label-active {
  background-color: #f4f6fb;
  color: rgba(0, 0, 0, 0.87);
  opacity: 1 !important;
}

//buttons

.mat-button {
  flex-direction: row;
  display: flex;
  align-items: center;
  place-content: center;
}

.mat-button-wrapper {
  place-content: center;
  align-items: center;
  display: flex !important;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding: 0 0 !important;
}

//expansion panel

.mat-expansion-panel-body {
  padding: 0 !important;
  margin-bottom: 24px;
}

.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: none;
  border: none;
}

.mat-expansion-panel-header {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 16px !important;
  margin-left: 16px;
  margin-right: 16px;
  box-sizing: border-box;
  border-top: 1px solid #d4d4d4;
  border-bottom: 1px solid #d4d4d4;
}

.mat-expansion-panel-header-title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0f0e1b;
}

//chips

.mat-chip-remove.mat-icon {
  display: flex;
  align-items: center;
}

/* width */
::-webkit-scrollbar {
  width: 2px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 5px;
  background: #d4d4d4;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(150, 150, 150);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #131212;
}

//icons

.arrow-back {
  display: flex;
  width: 24px;
  height: 24px;
  background: url("./assets/icon-arrow-left.svg") center;
  background-size: contain;
  background-repeat: no-repeat;
}

.location-icon {
  display: flex;
  background: url("./assets/icon-location.svg") center no-repeat;
  background-size: contain;
}

.edit-icon {
  display: flex;
  width: 24px;
  height: 24px;
  background: url("./assets/icon-edit.svg") center;
  background-size: contain;
  background-repeat: no-repeat;
}

.heart-icon-button {
  display: block;
  width: 24px;
  height: 24px;
  background: url("./assets/icon-heart-black.svg") center no-repeat;
  background-size: cover;
}

.share-icon-button {
  display: block;
  width: 24px;
  height: 24px;
  background: url("./assets/icon-share.svg") center no-repeat;
  background-size: cover;
}

.report-icon-button {
  display: block;
  width: 24px;
  height: 24px;
  background: url("./assets/icon-report.svg") center no-repeat;
  background-size: cover;
}

.like-icon-button {
  display: block;
  width: 24px;
  height: 24px;
  background: url("./assets/icon-like.svg") center no-repeat;
  background-size: cover;
}

.unlike-icon-button {
  display: block;
  width: 24px;
  height: 24px;
  background: url("./assets/icon-unlike.svg") center no-repeat;
  background-size: cover;
}

.eye-icon {
  display: block;
  width: 16px;
  height: 16px;
  background: url("./assets/icon-eye.svg") center no-repeat;
  background-size: cover;
}

.heart-icon {
  display: block;
  // width: 16px;
  // height: 16px;
  background: url("./assets/icon-heart-grey.svg") center no-repeat;
  background-size: cover;
}

.burger-icon {
  display: block;
  width: 24px;
  height: 24px;
  background: url("./assets/icon-burger.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.cancel-icon {
  display: block;
  width: 24px;
  height: 24px;
  background: url("./assets/icon-cancel.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.location-icon-small {
  display: flex;
  background: url("./assets/icon-location.svg") no-repeat center;
  background-size: contain;
  opacity: 0.5;
  width: 16px;
  height: 16px;
}

.clock-icon-small {
  display: flex;
  background: url("./assets/icon-clock.svg") no-repeat center;
  background-size: contain;
  width: 16px;
  height: 16px;
}

.user-icon-small {
  display: flex;
  background: url("./assets/icon-user.svg") no-repeat center;
  background-size: contain;
  width: 16px;
  height: 16px;
}

.chat-icon-button {
  display: block;
  width: 24px;
  height: 24px;
  background: url("./assets/icon-mail.svg") center no-repeat;
  background-size: cover;
}

.phone-icon-button {
  display: block;
  width: 24px;
  height: 24px;
  background: url("./assets/icon-phone.svg") center no-repeat;
  background-size: cover;
}

.heart-icon-active {
  display: flex;
  width: 24px;
  height: 24px;
  background: url("./assets/icon-favourite-active.svg") no-repeat center;
  background-size: contain;
}

.like-icon {
  display: flex;
  width: 24px;
  height: 24px;
  background: url("./assets/icon-like.svg") no-repeat center;
  background-size: contain;
}

.like-icon-active {
  display: flex;
  width: 24px;
  height: 24px;
  background: url("./assets/icon-like-active.svg") no-repeat center;
  background-size: contain;
}

.unlike-icon {
  display: flex;
  width: 24px;
  height: 24px;
  background: url("./assets/icon-unlike.svg") no-repeat center;
  background-size: contain;
}

.unlike-icon-active {
  display: flex;
  width: 24px;
  height: 24px;
  background: url("./assets/icon-unlike-active.svg") no-repeat center;
  background-size: contain;
}

//dialog

.mat-dialog-container {
  background-color: #f4f6fb;
}

//progress spinner
.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #fee000 !important;
  stroke-width: 5% !important;
}

html,
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

button:focus {
  outline: none;
}

app-button:focus {
  outline: none;
}

.mat-toolbar {
  background: white;
  display: flex;
  flex-direction: row;
  padding: 0 0 !important;
}

.topbar-logged-mobile {
  width: 100%;
  height: 100%;

  .mat-toolbar-row,
  .mat-toolbar-single-row {
    padding-left: 16px !important;
    padding-right: 16px !important;
    background-color: #f4f6fb !important;
  }
}

.mat-form-field-label-wrapper:focus {
  outline: none;
}

.big-dialog-style {
  mat-dialog-container {
    border-radius: 25px;
    padding: 40px;
  }

  .cdk-overlay-pane {
    border-radius: 30px;
  }
}

.small-dialog-style {
  mat-dialog-container {
    border-radius: 0;
    padding: 0px;
    overflow: hidden;
  }
}

.mat-form-field {
  color: #0f0e1b;
  width: 100%;
}

.mat-form-field-wrapper {
  width: 100%;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: #858585;
}

textarea::placeholder {
  color: #858585;
}

::placeholder {
  color: #858585;
}

textarea {
  font-family: Roboto;
}

.scrollable-content {
  text-align: center;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
  @supports (-webkit-touch-callout: none) {
    // overflow-y: hidden;
    // overflow-x: visible;
  }
}

.scrollable-content::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

rs-desktop {
  .vertical {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
    overflow-y: scroll;
  }

  .vertical::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  :root {
    scrollbar-color: rgb(210, 210, 210) rgb(46, 54, 69) !important;
    scrollbar-width: thin !important;
  }

  .mat-tab-body-content {
    // height: 100vh !important;
    // overflow-y: scroll !important;
  }
}

rs-mobile {
  .vertical {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
    overflow-y: scroll;
  }

  .vertical::-webkit-scrollbar {
    width: 0;
    height: 0;
    overflow-x: hidden;
  }
}

.mat-mini-fab.mat-accent {
  background-color: white;
}

.bg-primary {
  background-color: #fee000 !important;
  color: #0f0e1b;
}

.bg-secondary {
  background-color: #1da4d5 !important;
  color: white;
}

.bg-grey {
  background-color: #f4f6fb !important;
  color: black;
}

.bg-black {
  background-color: black !important;
  color: white;
}

.bg-white {
  background-color: white !important;
  color: black;
}

.bg-green {
  background-color: green !important;
  color: white;
}

.bg-red {
  background-color: red !important;
  color: white;
}

::ng-deep .tooltip {
  background: black;
  color: white;
  display: flex;
  align-items: flex-end;
  place-content: flex-end;
}
.material-icons {
  display: flex;
  align-items: center;
  place-content: center;
}
replica-avatar {
  cursor: pointer;
  border: 1px #cccccc solid;
  border-radius: 50%;
  background-color: #f4f6fb;
  .image-avatar {
    object-fit: none !important;
  }
  replica-image img {
    border-radius: 50%;
    object-fit: contain !important;
  }
}

hr {
  border: 0;
  border-top: 1px solid #d4d4d4;
  width: 100%;
}

//typography

.typo-title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #0F0E1B;
}

.typo-description {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #0F0E1B;
}

a {
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #0f0e1b;
}
.profile-buttons {
  height: 40px;
  margin-top: 24px;
  margin-bottom: 32px;
}

.typo-subheader {
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 24px;
color: #0F0E1B;
}

.mapboxgl-map {
  border-radius: 25px;
}
